import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.757 4.343a1 1 0 0 0-1.414 1.414L8.585 10l-4.242 4.243a1 1 0 1 0 1.414 1.414L10 11.414l4.242 4.243a1 1 0 0 0 1.414-1.414L11.414 10l4.243-4.243a1 1 0 0 0-1.415-1.414L10 8.586z" }, null, -1)
  ])))
}
export default { render: render }
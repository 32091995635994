<script setup lang="ts">
import CloseIcon from "@/assets/icons/close.svg?component"

interface Props {
  // more at: https://tailwindui.com/components/application-ui/elements/badges
  color?: "slate" | "green" | "red" | "emerald" | "indigo" | "amber" | "blue"
  size?: "small" | "medium"
  removable?: boolean
}

const emits = defineEmits(["remove"])

const remove = () => {
  emits("remove")
}

// these need to be explicit so tailwind will make them available
const colorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  slate: "bg-slate-50 text-slate-400 border-slate-100",
  green: "bg-green-50 text-green-500",
  emerald: "bg-emerald-50 text-emerald-500 border-emerald-100",
  red: "bg-red-50 text-red-500 border-red-100",
  indigo: "bg-indigo-50 text-indigo-500 border-indigo-100",
  amber: "bg-amber-50 text-amber-600 border-amber-200",
  blue: "bg-blue-50 text-blue-600 border-blue-200",
}

const sizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-4 text-xxs font-bold uppercase px-1 gap-1 rounded-sm",
  medium: "h-6 text-xs font-semibold px-2 gap-1 rounded",
}

const removeSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-3.5 w-3.5",
  medium: "h-4 w-4",
}

const props = withDefaults(defineProps<Props>(), {
  color: "slate",
  size: "small",
  removable: false,
})

const classes = computed(() => {
  const classes = [sizeClasses[props.size], colorClasses[props.color]]
  return classes
})

const removeSizeClass = computed(() => {
  return removeSizeClasses[props.size]
})
</script>

<template>
  <span
    class="inline-flex items-center border shrink flex-none"
    :class="classes"
  >
    <slot></slot>
    <button v-if="removable" type="button" @click="remove">
      <CloseIcon
        :class="removeSizeClass"
        class="text-current opacity-90 hover:opacity-100"
        aria-hidden="true"
      />
    </button>
  </span>
</template>
